import { React, useEffect, useState } from 'react';
import { useNavigate, useParams, Link, useLocation} from 'react-router-dom';
import { CatalogoCanje, CatalogoCanjeError } from './Paginas';
import { FaHome, FaSignInAlt, FaShoppingCart, FaTrash  } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { Modal, Button, closeButton } from 'react-bootstrap';
import ModalCanjeo from '../components/ModalCanjeo';


export function Canjeo() {
    const { id, token } = useParams(); // Obtén los parámetros de la URL
    const [contactName, setContactName] = useState('');
    const [canal, setCanal] = useState({});
    const [puntos, setPuntos] = useState([]);
    const [totalPuntos, setTotalPuntos] = useState(0);
    const [productos, setProductos] = useState([]);
    const [carrito, setCarrito] = useState([]);
    const [puntosRestantes, setPuntosRestantes] = useState(0); // Inicializar en 0 por defecto
    const [isValidSession, setIsValidSession] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [totalCarritoPuntos, setTotalCarritoPuntos] = useState(0);
    const [productosPreCanjeo, setProductosPreCanjeo] = useState([]);
    const [showModalCanjeo, setShowModalCanjeo] = useState(false);
    const [historialCanjeos, setHistorialCanjeos] = useState([]);
    const [detallesCanjeo, setDetallesCanjeo] = useState([]);
    const navigate = useNavigate();

    const irAlInicio = (e) => {
        e.preventDefault(); // Evita que el enlace redirija inmediatamente
    
        Swal.fire({
            title: '¿Cerrar sesión y volver al inicio?',
            text: 'Al regresar a la página de inicio, tu sesión actual se cerrará. ¿Estás seguro de que deseas continuar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#0085AD',
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();  // Obtener el botón de confirmación
                            confirmButton.style.backgroundColor = '#0085AD';  // Cambiar el color de fondo
                            confirmButton.style.color = 'white';              // Cambiar el color del texto
                            confirmButton.style.border = 'none';              // Opcional: eliminar el borde
                        },
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = '/'; 
            }
        });
    };

    // Recuperar carrito de localStorage cuando la página cargue
    useEffect(() => {
        const carritoGuardado = localStorage.getItem('carrito');
        if (carritoGuardado) {
        setCarrito(JSON.parse(carritoGuardado));
        }
    }, []);

    // Guardar el carrito en localStorage cada vez que cambie
    useEffect(() => {
        if (carrito.length > 0) {
        localStorage.setItem('carrito', JSON.stringify(carrito));
        }
    }, [carrito]);

    useEffect(() => {
        const validateSession = async () => {
            try {
                const response = await fetch(`https://system-befocused-production.up.railway.app/canjeo/${id}/${token}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                });
        
                const data = await response.json();
        
                if (response.ok && data.isValid) {
                setContactName(data.contactName);
                setCanal(data.canal[0]);
                setPuntos(data.puntos);
        
                const total = data.puntos
                    .map((punto) => parseFloat(punto.PUNT_total))
                    .reduce((sum, punto) => sum + punto, 0);
                
                setTotalPuntos(total.toFixed(2));
                setIsValidSession(true);
                } else {
                console.log('Sesión no válida. Redirigiendo al login.');
                setIsValidSession(false);
                navigate('/login');
                }
            } catch (error) {
                console.error('Error al validar la sesión:', error);
                setIsValidSession(false);
            }
        };
    
        validateSession();
    }, [id, token, navigate]);

    useEffect(() => {
        fetch('https://system-befocused-production.up.railway.app/productos')
            .then((response) => response.json())
            .then((data) => setProductos(data.productos))
            .catch((error) => console.error('Error al obtener los productos:', error));
    }, []);

    // Este useEffect se asegura de que puntosRestantes se actualice después de que totalPuntos se calcule
    useEffect(() => {
        setPuntosRestantes(parseFloat(totalPuntos));
    }, [totalPuntos]);

    useEffect(() => {
        const totalCarrito = carrito
            .map((item) => item.cantidad * item.PROD_total)
            .reduce((sum, current) => sum + current, 0);
            setTotalCarritoPuntos(totalCarrito);
            setPuntosRestantes(parseFloat(totalPuntos) - totalCarrito);
    }, [carrito, totalPuntos]);

    const agregarAlCarrito = (producto) => {
        if (puntosRestantes >= producto.PROD_total) {
            const productoEnCarrito = carrito.find((item) => item.PROD_ID === producto.PROD_ID);
        
            if (productoEnCarrito) {
                setCarrito(
                carrito.map((item) =>
                    item.PROD_ID === producto.PROD_ID ? { ...item, cantidad: item.cantidad + 1 } : item
                )
                );
            } else {
                setCarrito([...carrito, { ...producto, cantidad: 1 }]);
            }
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Puntos insuficientes',
                    text: 'No tienes suficientes puntos para agregar más productos.',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#0085AD',
                        didOpen: () => {
                            const confirmButton = Swal.getConfirmButton();  // Obtener el botón de confirmación
                            confirmButton.style.backgroundColor = '#0085AD';  // Cambiar el color de fondo
                            confirmButton.style.color = 'white';              // Cambiar el color del texto
                            confirmButton.style.border = 'none';              // Opcional: eliminar el borde
                        }
                });
        }
    };

    const eliminarProducto = (PROD_ID) => {
        Swal.fire({
            title: '¿Eliminar producto?',
            text: "El producto será eliminado del carrito.",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#0085AD',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            }).then((result) => {
            if (result.isConfirmed) {
                setCarrito(carrito.filter((item) => item.PROD_ID !== PROD_ID));
                Swal.fire({
                    title: 'Eliminado',
                    text: 'El producto ha sido eliminado.',
                    icon: 'success',
                    confirmButtonColor: '#0085AD',
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();  // Obtener el botón de confirmación
                        confirmButton.style.backgroundColor = '#0085AD';  // Cambiar el color de fondo
                        confirmButton.style.color = 'white';              // Cambiar el color del texto
                        confirmButton.style.border = 'none';              // Opcional: eliminar el borde
                    }
                });
            }
        });
    };

    const borrarTodo = () => {
        Swal.fire({
            title: '¿Eliminar todos los productos?',
            text: "Esto vaciará el carrito.",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#0085AD',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar todo'
        }).then((result) => {
            if (result.isConfirmed) {
                setCarrito([]); // Vacía el carrito
                localStorage.removeItem('carrito');
                Swal.fire({
                    title: 'Carrito vacío',
                    text: 'Todos los productos han sido eliminados.',
                    icon: 'success',
                    confirmButtonColor: '#0085AD',
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();  
                        confirmButton.style.backgroundColor = '#0085AD';
                        confirmButton.style.color = 'white';
                        confirmButton.style.border = 'none';
                    }
                });
            }
        });
    };
    
    const incrementarCantidad = (PROD_ID) => {
        const productoEnCarrito = carrito.find((item) => item.PROD_ID === PROD_ID);
    
        if (puntosRestantes >= productoEnCarrito.PROD_total) {
            setCarrito(
                carrito.map((item) =>
                item.PROD_ID === PROD_ID ? { ...item, cantidad: item.cantidad + 1 } : item
                )
            );
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Puntos insuficientes',
                text: 'No tienes suficientes puntos para agregar más productos.',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0085AD',
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();  // Obtener el botón de confirmación
                        confirmButton.style.backgroundColor = '#0085AD';  // Cambiar el color de fondo
                        confirmButton.style.color = 'white';              // Cambiar el color del texto
                        confirmButton.style.border = 'none';              // Opcional: eliminar el borde
                    }
            });
        }
    };
    
    const decrementarCantidad = (PROD_ID) => {
        setCarrito(
            carrito.map((item) =>
                item.PROD_ID === PROD_ID && item.cantidad > 1
                ? { ...item, cantidad: item.cantidad - 1 }
                : item
            )
        );
    };

    const canjearProductos = () => {
        // Guardamos los productos seleccionados como pre-canjeados
        setProductosPreCanjeo(carrito);
        // Restar los puntos de los productos seleccionados
        const puntosRestantes = totalPuntos - carrito.reduce((acc, item) => acc + (item.PROD_total * item.cantidad), 0);
        // Redirigir a la página de canjeo con los puntos restantes y productos pre canjeados
        navigate(`/canjear/${id}/${token}`, {
            state: {
                productosPreCanjeo: carrito,
                puntosRestantes
            }
        });
    };

    // Función para cerrar el modal
    const handleCloseModalCanjeo = () => setShowModalCanjeo(false);

     // Función para abrir el modal y cargar el historial de canjeos
    const handleShowModalCanjeo = async () => {
        try {
            const response = await fetch(`https://system-befocused-production.up.railway.app/historial-canjeos?canalId=${canal.CANAL_ID}`);
            const data = await response.json();
            setHistorialCanjeos(data);  // Guardamos las fechas de canjeo
            setShowModalCanjeo(true);  // Mostramos el modal
        } catch (error) {
            console.error('Error al obtener el historial:', error);
        }
    };

    // Función para obtener el detalle del canjeo por fecha
    const fetchDetalleCanjeo = async (fechaActuacion) => {
        try {
            const response = await fetch(`https://system-befocused-production.up.railway.app/historial-canjeos-detalle?canalId=${canal.CANAL_ID}&fechaActuacion=${fechaActuacion}`);
            const data = await response.json();
            setDetallesCanjeo(data);  // Guardar los detalles de los productos canjeados
        } catch (error) {
            console.error('Error al obtener el detalle del canjeo:', error);
        }
    };
    

    if (!isValidSession) {
        return (
            <div>
                <header className="site-header" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
                    <nav className="navbar navbar-expand-lg nav-bottom-line center-brand static-nav" style={{ background: 'white' }}>
                        <div className="container d-flex align-items-center justify-content-between">
                            <a className="navbar-brand d-flex align-items-center" href="/" style={{ position: 'absolute', left: '0', right:'70%' }}>
                                <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-default" />
                                <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-scrolled" />
                            </a>
                            <button className="navbar-toggler navbar-toggler-right d-none collapsed" type="button" data-toggle="collapse" data-target="#xenav">
                                <span> </span>
                                <span> </span>
                                <span> </span>
                            </button>
                            <div className="collapse navbar-collapse" id="xenav">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <a className="nav-link pagescroll" style={{ color: '#000' }} href="/">
                                        <FaHome style={{ marginRight: '8px' }} /> Ir al Inicio
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link pagescroll" style={{ color: '#000' }} href="/login">
                                        <FaSignInAlt style={{ marginRight: '8px' }} /> Iniciar sesión
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <CatalogoCanjeError />
            </div>
        );
    }

    return (
        <div>
            <header className="site-header" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
                <nav className="navbar navbar-expand-lg nav-bottom-line center-brand static-nav" style={{ background: 'white' }}>
                    <div className="container d-flex align-items-center justify-content-between">
                        <a className="navbar-brand d-flex align-items-center" href="/" style={{ position: 'absolute', left: '0', right:'70%' }}>
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-default" />
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-scrolled" />
                        </a>
                        <button className="navbar-toggler navbar-toggler-right d-none collapsed" type="button" data-toggle="collapse" data-target="#xenav">
                            <span> </span>
                            <span> </span>
                            <span> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="xenav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <p style={{ marginBottom: '0px' }}>
                                        {contactName} | <strong>{canal.CANAL_nombre}</strong>
                                    </p>
                                    <p>Puntos: {puntosRestantes.toLocaleString('es-MX', { maximumFractionDigits: 0 })}</p>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" style={{ color: '#000', marginTop: '0px', marginBottom: '0px', paddingTop: '0px', paddingBottom: '0px', cursor:'pointer' }} onClick={() => setShowModal(true)}>
                                        <FaShoppingCart style={{ marginRight: '8px' }} />
                                        Carrito
                                        {/* Span con contador de productos en el carrito */}
                                        <span style={{
                                            backgroundColor: '#0085ad',
                                            color: 'white',
                                            borderRadius: '50%',
                                            padding: '8px',
                                            width: '24px', // Tamaño fijo para hacer que sea redondo
                                            height: '24px', // Igual que el width para mantener la proporción
                                            display: 'inline-flex', // Alinea el contenido del span
                                            justifyContent: 'center', // Centra horizontalmente
                                            alignItems: 'center', // Centra verticalmente
                                            fontSize: '12px', // Tamaño de la fuente más pequeño si hay un solo dígito
                                            marginLeft: '10px'
                                            }}>
                                            {carrito.length}
                                        </span>
                                    </a>

                                    <a className="nav-link pagescroll" style={{ color: '#000', marginTop: '0px', marginBottom: '0px', paddingTop: '0px', paddingBottom: '0px' }} href="/" onClick={irAlInicio}>
                                        <FaHome style={{ marginRight: '8px' }} /> Ir al Inicio
                                    </a>
                                </li>
                            </ul>
                        </div>

                        
                    </div>
                </nav>
            </header>

            <section className="combined_skills_section big_padding">
                <section className="skill_section" style={{ paddingTop: '50px' }}>
                    <div className="container">
                        <div className="col-md-12">
                            <a href="#" 
                                className="btn btn-yellow-black btn-rounded open_sans bg_before_navy" 
                                style={{ width: '220px', marginBottom: '10px', marginTop: '5px' }}
                                onClick={handleShowModalCanjeo}  // Función para mostrar el modal
                            >
                                Mis Canjes
                            </a>
                            <ModalCanjeo
                                show={showModalCanjeo}
                                onHide={handleCloseModalCanjeo}
                                historialCanjeos={historialCanjeos}
                            />

                            <div className="row">
                                {productos.map((producto) => {
                                    // Verificar si el producto ya está en el carrito
                                    const productoEnCarrito = carrito.find((item) => item.PROD_ID === producto.PROD_ID);
                                    const cantidadSeleccionada = productoEnCarrito ? productoEnCarrito.cantidad : 0;

                                    return (
                                        <div className="slide col-4" key={producto.PROD_ID}>
                                            <div className="team_detail wow slideInLeft team_item">
                                                <div className="team_detail_inner">
                                                    <img
                                                        src={`/agency-studio/img/canjeo/${producto.PROD_ruta_imagen}.png`}
                                                        alt={producto.PROD_nombre}
                                                    />
                                                </div>

                                                <button
                                                    onClick={() => agregarAlCarrito(producto)}  // Llamar la función al hacer clic
                                                    className={`btn btn-rounded open_sans bg_before_navy ${
                                                        productoEnCarrito ? 'btn-yellow-black' : 'btn-blue-black'
                                                    }`}
                                                    style={{
                                                        marginTop: '10px',
                                                        width: '190px',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {productoEnCarrito
                                                        ? `Seleccionado: ${cantidadSeleccionada}`
                                                        : `${Math.floor(producto.PROD_total)} PanPoints`}
                                                </button>

                                                <p className="default_text text-center open_sans">
                                                    {producto.PROD_nombre}
                                                </p>
                                                <ul className="team_members_list"></ul>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                        </div>
                    </div>
                </section>
            </section>

            {/* Modal del carrito */}
            <Modal 
            show={showModal} 
            onHide={() => setShowModal(false)} 
            size="lg" // Hacer el modal más ancho
            centered 
            backdrop="true"
            >
                <Modal.Header>
                    <Modal.Title>Selección de Productos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {carrito.length === 0 ? (
                    <p style={{margin:'10px 0px'}}>No hay productos en el carrito.</p>
                    ) : (
                    <table className="table">
                        <thead>
                        <tr>
                            <th style={{width:'30%'}}>Producto</th>
                            <th>Puntos</th>
                            <th>Cantidad</th>
                            <th style={{textAlign:'right'}}>Total</th>
                            <th>Eliminar</th>
                        </tr>
                        </thead>
                        <tbody>
                        {carrito.map((item) => (
                            <tr key={item.PROD_ID}>
                            <td>{item.PROD_nombre}</td>
                            <td>{parseInt(item.PROD_total).toLocaleString('es-MX')}</td>
                            <td>
                                <button className='button-dicrement' onClick={() => decrementarCantidad(item.PROD_ID)}>-</button>
                                <span style={{ margin: '0 10px' }}>{item.cantidad}</span>
                                <button className='button-increment' onClick={() => incrementarCantidad(item.PROD_ID)}>+</button>
                            </td>
                            <td style={{textAlign:'right'}}>{(item.PROD_total * item.cantidad).toLocaleString('es-MX')}</td>
                            <td style={{textAlign:'center'}}>
                                <button className='buttonTrash' onClick={() => eliminarProducto(item.PROD_ID)}>
                                <FaTrash />
                                </button>
                            </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div>
                            <p style={{ marginBottom: '0px' }}>Puntos a canjear: {totalCarritoPuntos.toLocaleString('es-MX')}</p>
                            <p>Puntos restantes: {puntosRestantes.toLocaleString('es-MX')}</p>
                        </div>
                        {carrito.length > 0 && ( 
                            <a 
                                className=''
                                style={{cursor:'pointer'}}
                                onClick={borrarTodo}>
                                <FaTrash /> Borrar todo
                            </a>
                        )}
                    </div>

                    <Button 
                    className='btn btn-red btn-rounded btn-large open_sans bg_before_navy' 
                    onClick={() => setShowModal(false)}
                    >
                    Cerrar
                    </Button>

                    <Button 
                    className='btn btn-yellow-black btn-large btn-rounded contact_btn' 
                    style={{marginLeft: 'auto', float:'right' }} 
                    onClick={canjearProductos}  // Pasa a pre-canjeo y navega a la página de canje
                    >
                    Canjear
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export function Envio() {
    const { id, token } = useParams();
    const location = useLocation();
    const { productosPreCanjeo, puntosRestantes } = location.state || {};
    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [canal, setCanal] = useState({});
    const [puntos, setPuntos] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [showModal, setShowModal] = useState(false);
    const [carrito, setCarrito] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const validarToken = async () => {
        try {
            const response = await fetch(`https://system-befocused-production.up.railway.app/canjeo/${id}/${token}`);
            const data = await response.json();

            //console.log("Fetched data:", data);

            if (data.isValid) {
            setContactName(data.contactName);
            setCanal(data.canal[0]);
            setPuntos(data.puntos);

            if (data.contacto && data.contacto[0] && data.contacto[0].CONT_email) {
                setEmail(data.contacto[0].CONT_email); // Adjusted access
            } else {
                //console.error("Email not found in contacto.");
            }

            setIsValid(true);
            } else {
            // Si no es válido, redirigir a la página de login o algún otro lugar
            navigate('/login');
            }
        } catch (error) {
            console.error('Error al validar token:', error);
            navigate('/login');
        }
        };

        validarToken();
    }, [id, token, navigate]);

    

    // Función para cerrar el modal
    const handleClose = () => setShowModal(false);
    // Función para abrir el modal
    const handleShow = () => setShowModal(true);

    // Función para regresar a la página de canjeo
    const handleRegresarCanjeo = () => navigate(`/canjeo/${id}/${token}`);
    const totalPuntos = productosPreCanjeo.reduce((acc, item) => acc + (parseInt(item.PROD_total) * item.cantidad), 0);


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Obtener los valores del formulario
        const formData = new FormData(e.target);
        const direccion = {
            nombre_solicitante: formData.get('nombre_solicitante'),
            correo: formData.get('correo'),
            telefono: formData.get('telefono'),
            calle_num_ext: formData.get('calle_num_ext'),
            colonia: formData.get('colonia'),
            municipio: formData.get('municipio'),
            estado: formData.get('estado'),
            codigo_postal: formData.get('codigo_postal'),
            entre_calles: formData.get('entre_calles'),
            notas: formData.get('notas')
        };

        // Datos para enviar al backend
        const data = {
            contId: id,
            canalId: canal.CANAL_ID,
            productos: productosPreCanjeo,
            direccion
        };

        // Cambiar el estado de carga
        setLoading(true);

        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/canjeo/registrar', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: '¡Canje exitoso!',
                    text: 'Gracias por participar',
                    confirmButtonColor: '#0085AD',
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();  // Obtener el botón de confirmación
                        confirmButton.style.backgroundColor = '#0085AD';  // Cambiar el color de fondo
                        confirmButton.style.color = 'white';              // Cambiar el color del texto
                        confirmButton.style.border = 'none';              // Opcional: eliminar el borde
                    },
                }).then(() => {
                    setCarrito([]); // Limpiar el carrito en el estado
                    localStorage.removeItem('carrito');
                    navigate(`/canjeo/${id}/${token}`);
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: result.message || 'Hubo un problema con el canjeo.',
                });
            }
        } catch (error) {
            console.error('Error en el proceso de canjeo:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al procesar el canjeo.',
            });
        } finally {
            // Cambiar el estado de carga
            setLoading(false);
        }
    };

    if (!isValid) {
        return <p>Validando...</p>;
    }


    return (
        <div>
            <header className="site-header" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
                <nav className="navbar navbar-expand-lg nav-bottom-line center-brand static-nav" style={{ background: 'white' }}>
                    <div className="container d-flex align-items-center justify-content-between">
                        <a className="navbar-brand d-flex align-items-center" href="/" style={{ position: 'absolute', left: '0', right:'70%' }}>
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-default" />
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-scrolled" />
                        </a>
                        <button className="navbar-toggler navbar-toggler-right d-none collapsed" type="button" data-toggle="collapse" data-target="#xenav">
                            <span> </span>
                            <span> </span>
                            <span> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="xenav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <p style={{marginBottom:'0px'}}>{contactName} | <strong>{canal.CANAL_nombre}</strong></p>
                                    <p>Puntos restantes: {puntosRestantes.toLocaleString('es-MX')}</p>
                                </li>
                                <li className="nav-item">
                                    {/* <a href="#" className="nav-link pagescroll" onClick={handleShow} style={{ color: '#000', margin: '0px', padding: '0px' }}>
                                        <FaShoppingCart style={{ marginRight: '8px' }} /> Ver Productos canjeados
                                    </a> */}
                                    <a href="#" className="nav-link pagescroll" onClick={handleRegresarCanjeo} style={{ color: '#000', margin: '0px', padding: '0px' }}>
                                        <FaHome style={{ marginRight: '8px' }} /> Regresar a canje
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <section className="combined_skills_section big_padding">
                <section className="skill_section" style={{ paddingTop: '50px' }}>
                    <div className="container">
                        <div className="row">
                            {/* Columna izquierda: Productos Canjeados */}
                            <div className="table-detail col-md-4 d-flex flex-column justify-content-start" style={{ paddingRight: '20px' }}>
                                <h4>Selección de Productos</h4>
                                <table className="table" style={{ fontSize: '14px', lineHeight:'16px' }}>
                                    <thead>
                                        <tr>
                                            <th>Producto</th>
                                            <th>Puntos</th>
                                            <th>Cantidad</th>
                                            {/* <th>Total</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productosPreCanjeo.map((item) => (
                                            <tr key={item.PROD_ID}>
                                                <td>{item.PROD_nombre}</td>
                                                <td style={{textAlign:'center'}}>{parseInt(item.PROD_total).toLocaleString('es-MX')}</td>
                                                <td style={{textAlign:'center'}}>{item.cantidad}</td>
                                                {/* <td style={{textAlign:'center'}}>{(item.PROD_total * item.cantidad).toLocaleString('es-MX')}</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px' }}>
                                    <p style={{ fontWeight: '700', fontSize: '20px', margin: '0 10px 0 0' }}>
                                        PanPoints <br /> a canjear
                                    </p>
                                    <span style={{
                                        backgroundColor: '#0085ad',
                                        color: 'white',
                                        borderRadius: '50%',
                                        padding: '35px',
                                        width: '24px', // Tamaño fijo para hacer que sea redondo
                                        height: '24px', // Igual que el width para mantener la proporción
                                        display: 'inline-flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '20px',
                                        fontWeight: '700'
                                    }}>
                                        {totalPuntos.toLocaleString('es-MX')}
                                    </span>
                                </div>


                            </div>

                            {/* Columna derecha: Formulario de envío */}
                            <div className="address-details col-md-8 d-flex flex-column justify-content-start" style={{ paddingLeft: '20px' }}>
                            <form className="form-address" onSubmit={handleSubmit}>
                                <div className="row">
                                    {/* Campos del formulario */}
                                    <div className="col-md-6 ">
                                        <input type="text" name="nombre_solicitante" className="form-control" placeholder="Nombre completo del solicitante" required />
                                    </div>
                                    <div className="col-md-6 ">
                                        <input type="email" name="correo" className="form-control" placeholder="Correo electrónico" value={email} readOnly required />
                                    </div>
                                    <div className="col-md-6 ">
                                        <input type="text" name="telefono" className="form-control" placeholder="Número de celular" inputMode="numeric" pattern="\d{10}" maxLength="10" required />
                                    </div>
                                    <div className="col-md-6 ">
                                        <input type="text" name="calle_num_ext" className="form-control" placeholder="Calle y Número Ext." required />
                                    </div>
                                    <div className="col-md-6 ">
                                        <input type="text" name="colonia" className="form-control" placeholder="Colonia" required />
                                    </div>
                                    <div className="col-md-6 ">
                                        <input type="text" name="municipio" className="form-control" placeholder="Municipio o Delegación" required />
                                    </div>
                                    <div className="col-md-6 ">
                                        <input type="text" name="estado" className="form-control" placeholder="Estado" required />
                                    </div>
                                    <div className="col-md-6 ">
                                        <input type="text" name="codigo_postal" className="form-control" placeholder="Código Postal" inputMode="numeric" pattern="\d{5}" required />
                                    </div>
                                    <div className="col-md-12 ">
                                        <textarea name="entre_calles" className="form-control" placeholder="Entre Calles o Referencias" rows="3" required></textarea>
                                    </div>
                                    <div className="col-md-12 ">
                                        <textarea name="notas" className="form-control" placeholder="Notas (quién recibe, horario de atención u otros)" rows="3"></textarea>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <button type="submit" className="btn btn-yellow-black btn-large btn-rounded contact_btn" disabled={loading}>
                                        {loading ? 'Canjeando...' : 'Enviar'}
                                    </button>
                                </div>
                            </form>
                            </div>

                        </div>
                    </div>
                </section>
            </section>



  
        </div>
    )
}


export default Canjeo;