import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    // Usar el hook useEffect para redirigir después de unos segundos
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');  // Redirigir al Home después de 3 segundos
        }, 1000);

        // Limpiar el temporizador si el componente se desmonta antes de la redirección
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Error 404: Página no encontrada</h1>
            <p>Redirigiendo al inicio en 3 segundos...</p>
        </div>
    );
};

export default NotFound;
