import React from 'react';
import { Modal, Button } from 'react-bootstrap';  // Importar Modal y Button de react-bootstrap

function ModalCanjeo({ show, onHide, historialCanjeos }) {
    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header>
                <Modal.Title style={{ color: '#000' }}>Historial de Canjes BeFocused</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {historialCanjeos.length > 0 ? (
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{width:'30%'}}>Producto</th>
                                <th style={{textAlign:'center'}}>Puntos Usados</th>
                                <th>Fecha y Hora </th>
                            </tr>
                        </thead>
                        <tbody>
                            {historialCanjeos.map((canjeo, index) => (
                                <tr key={index}>
                                    <td>{canjeo.PROD_nombre}</td>
                                    <td style={{textAlign:'center'}}>{Math.abs(canjeo.PP_canjeados)}</td>
                                    <td>
                                        {new Date(canjeo.PP_actuacion).toLocaleString('es-MX', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false,
                                        })}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p style={{marginTop:'12px', marginBottom:'20px'}}>No se encontraron canjes anteriores.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-yellow-black btn-large btn-rounded contact_btn' onClick={onHide}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalCanjeo;
