import React, { useEffect, useState } from 'react';
import { HeaderDos } from '../widgets/Widgets';
import { FaHome, FaSignInAlt } from 'react-icons/fa';

function Paginas() {
    return (
        <div>Paginas</div>
    );
}

export function Catalogo() {
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        // Hacer la solicitud al backend para obtener los productos
        fetch('https://system-befocused-production.up.railway.app/productos')
            .then((response) => response.json())
            .then((data) => {
                setProductos(data.productos);
            })
            .catch((error) => {
                console.error('Error al obtener los productos:', error);
            });
    }, []);

    return (
        <div>
            <HeaderDos />
            <section className="combined_skills_section big_padding">
                <section className="skill_section" style={{ paddingTop: '50px' }}>
                    <div className="container">
                        <div className="col-md-12">
                            <div className="row">
                                {/* Mapeamos los productos obtenidos de la base de datos */}
                                {productos.map((producto) => (
                                    <div className="slide col-4" key={producto.PROD_ID}>
                                        <div className="team_detail wow slideInLeft team_item">
                                            <div className="team_detail_inner">
                                                <img
                                                    src={`agency-studio/img/canjeo/${producto.PROD_ruta_imagen}.png`}
                                                    alt={producto.PROD_nombre}
                                                />
                                            </div>
                                            <a
                                                href="#"
                                                className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy"
                                                style={{
                                                    marginTop: '10px',
                                                    width: '190px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {Math.floor(producto.PROD_total)} PanPoints
                                            </a>
                                            <p className="default_text text-center open_sans">
                                                {producto.PROD_nombre}
                                            </p>
                                            <ul className="team_members_list"></ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export function CatalogoCanje() {
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        // Hacer la solicitud al backend para obtener los productos
        fetch('https://system-befocused-production.up.railway.app/productos')
            .then((response) => response.json())
            .then((data) => {
                setProductos(data.productos);
            })
            .catch((error) => {
                console.error('Error al obtener los productos:', error);
            });
    }, []);

    return (
        <div>
            <section className="combined_skills_section big_padding">
                <section className="skill_section" style={{ paddingTop: '50px' }}>
                    <div className="container">
                        <div className="col-md-12">
                            <div className="row">
                                {/* Mapeamos los productos obtenidos de la base de datos */}
                                {productos.map((producto) => (
                                    <div className="slide col-4" key={producto.PROD_ID}>
                                        <div className="team_detail wow slideInLeft team_item">
                                            <div className="team_detail_inner">
                                                <img
                                                    src={`/agency-studio/img/canjeo/${producto.PROD_ruta_imagen}.png`}
                                                    alt={producto.PROD_nombre}
                                                />
                                            </div>
                                            <a
                                                href="/login"
                                                className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy"
                                                style={{
                                                    marginTop: '10px',
                                                    width: '190px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {Math.floor(producto.PROD_total)} PanPoints
                                            </a>
                                            <p className="default_text text-center open_sans">
                                                {producto.PROD_nombre}
                                            </p>
                                            <ul className="team_members_list"></ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export function CatalogoCanjeError() {
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        // Hacer la solicitud al backend para obtener los productos
        fetch('https://system-befocused-production.up.railway.app/productos')
            .then((response) => response.json())
            .then((data) => {
                setProductos(data.productos);
            })
            .catch((error) => {
                console.error('Error al obtener los productos:', error);
            });
    }, []);

    return (
        <div>
            <header className="site-header" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
                <nav className="navbar navbar-expand-lg nav-bottom-line center-brand static-nav" style={{ background: 'white' }}>
                    <div className="container d-flex align-items-center justify-content-between">
                        <a className="navbar-brand d-flex align-items-center" href="/" style={{ position: 'relative' }}>
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-default" />
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" alt="logo" className="logo-scrolled" />
                        </a>
                        <button className="navbar-toggler navbar-toggler-right d-none collapsed" type="button" data-toggle="collapse" data-target="#xenav">
                            <span> </span>
                            <span> </span>
                            <span> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="xenav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" style={{ color: '#000' }} href="/">
                                    <FaHome style={{ marginRight: '8px' }} /> Ir al Inicio
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link pagescroll" style={{ color: '#000' }} href="/login">
                                    <FaSignInAlt style={{ marginRight: '8px' }} /> Iniciar sesión
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
            <section className="combined_skills_section big_padding">
                <section className="skill_section" style={{ paddingTop: '50px' }}>
                    <div className="container">
                        <div className="col-md-12">
                            <div className="row">
                                {/* Mapeamos los productos obtenidos de la base de datos */}
                                {productos.map((producto) => (
                                    <div className="slide col-4" key={producto.PROD_ID}>
                                        <div className="team_detail wow slideInLeft team_item">
                                            <div className="team_detail_inner">
                                                <img
                                                    src={`/agency-studio/img/canjeo/${producto.PROD_ruta_imagen}.png`}
                                                    alt={producto.PROD_nombre}
                                                />
                                            </div>
                                            <a
                                                href="/login"
                                                className="btn btn-blue-black btn-rounded btn-large open_sans bg_before_navy"
                                                style={{
                                                    marginTop: '10px',
                                                    width: '190px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {Math.floor(producto.PROD_total)} PanPoints
                                            </a>
                                            <p className="default_text text-center open_sans">
                                                {producto.PROD_nombre}
                                            </p>
                                            <ul className="team_members_list"></ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export function Registro() {
    return (
        <div>
            <HeaderDos />
            <section className="circle_pie_section" style={{ marginTop: '8rem' }}>
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSegjzLlt1CUMIFtTHc4yE7W75gbcIkgvGWV5Hm0gBgM_IOJYQ/viewform?embedded=true"
                    width="640"
                    height="1737"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0"
                >
                    Cargando…
                </iframe>
            </section>
        </div>
    );
}

export default Paginas;