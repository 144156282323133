import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import { HeaderDos } from '../widgets/Widgets.js';
import Swal from 'sweetalert2';
import { AuthContext } from '../context/AuthContext';

export function Login() {
    const [correo, setCorreo] = useState('');
    const [token, setToken] = useState('');
    const [tokenRequested, setTokenRequested] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate(); // Hook para navegar
    const [showModal, setShowModal] = useState(true);

    const { login } = useContext(AuthContext);

    // Función para solicitar el token
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/verificar-correo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ correo })
            });

            const data = await response.json();

            if (response.ok && data.message.includes('Token generado')) {
                // Mostrar alerta de éxito usando SweetAlert
                Swal.fire({
                    icon: 'success',
                    title: 'Token enviado',
                    text: 'Enviamos un token de 4 dígitos a tu correo electrónico. Por favor verifica y coloca el token en el campo.',
                    confirmButtonText: 'Entendido',
                    confirmButtonColor: '#0085AD',
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();  // Obtener el botón de confirmación
                        confirmButton.style.backgroundColor = '#0085AD';  // Cambiar el color de fondo
                        confirmButton.style.color = 'white';              // Cambiar el color del texto
                        confirmButton.style.border = 'none';              // Opcional: eliminar el borde
                      }
                });

                setTokenRequested(true); // Cambia el estado para mostrar el campo de token
            } else if (response.status === 429) { // Manejar el estado 429
                // Mostrar alerta de exceso de solicitudes usando SweetAlert
                Swal.fire({
                    icon: 'warning',
                    title: 'Demasiadas solicitudes',
                    text: data.message || 'Ya se generó un token recientemente. Intenta nuevamente en 5 minutos.',
                    confirmButtonText: 'Entendido',
                    confirmButtonColor: '#0085AD',
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();  // Obtener el botón de confirmación
                        confirmButton.style.backgroundColor = '#0085AD';  // Cambiar el color de fondo
                        confirmButton.style.color = 'white';              // Cambiar el color del texto
                        confirmButton.style.border = 'none';              // Opcional: eliminar el borde
                      }
                });
            } else {
                // Mostrar alerta de error si el correo no está registrado
                Swal.fire({
                    icon: 'error',
                    title: 'Correo electrónico no registrado',
                    text: 'Este correo electrónico no fue encontrado. Por favor verifica y asegúrate de ingresar un correo registrado.',
                    confirmButtonText: 'Reintentar',
                    confirmButtonColor: '#0085AD',
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();  // Obtener el botón de confirmación
                        confirmButton.style.backgroundColor = '#0085AD';  // Cambiar el color de fondo
                        confirmButton.style.color = 'white';              // Cambiar el color del texto
                        confirmButton.style.border = 'none';              // Opcional: eliminar el borde
                      }
                });
            }
            } catch (error) {
                console.error('Error al solicitar el token:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error del servidor',
                    text: 'Hubo un problema al procesar tu solicitud. Por favor intenta más tarde.',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#0085AD',
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();  // Obtener el botón de confirmación
                        confirmButton.style.backgroundColor = '#0085AD';  // Cambiar el color de fondo
                        confirmButton.style.color = 'white';              // Cambiar el color del texto
                        confirmButton.style.border = 'none';              // Opcional: eliminar el borde
                      }
                });
            } finally {
                setIsLoading(false); // Desactivamos el estado de carga cuando se complete la solicitud
            }
    };

    // Función para validar el token
    const handleTokenValidation = async () => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/validar-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ correo, token })
            });

            const data = await response.json();
            setValidationMessage(data.message);

            // Si el token es válido, redirigir a /Canjeo
            if (response.ok && data.message === 'Token válido') {
                const contactId = data.contactId; // Asegúrate de que el backend regrese el CONT_ID
                login(contactId);
                navigate(`/canjeo/${contactId}/${token}`);
            } else {
                // Mostrar alerta de token inválido si no es correcto
                Swal.fire({
                    icon: 'error',
                    title: 'Token inválido',
                    text: 'El token ingresado es incorrecto. Por favor verifica y vuelve a intentar.',
                    confirmButtonText: 'Reintentar',
                    confirmButtonColor: '#0085AD',
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();  // Obtener el botón de confirmación
                        confirmButton.style.backgroundColor = '#0085AD';  // Cambiar el color de fondo
                        confirmButton.style.color = 'white';              // Cambiar el color del texto
                        confirmButton.style.border = 'none';              // Opcional: eliminar el borde
                    }
                });
            }
        } catch (error) {
            console.error('Error en la validación del token:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error del servidor',
                text: 'Hubo un problema al validar el token. Por favor intenta más tarde.',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#0085AD',
                    didOpen: () => {
                        const confirmButton = Swal.getConfirmButton();  // Obtener el botón de confirmación
                        confirmButton.style.backgroundColor = '#0085AD';  // Cambiar el color de fondo
                        confirmButton.style.color = 'white';              // Cambiar el color del texto
                        confirmButton.style.border = 'none';              // Opcional: eliminar el borde
                      }
            });
        }
    };

    useEffect(() => {
        // Mostrar el modal cuando el componente se monta (al acceder a la URL /login)
        setShowModal(true);
    }, []);

    const handleClose = () => setShowModal(false);

    return (
        <div>
            <section className="page_content_parent_section">
                <HeaderDos />
                <hgroup style={{ marginTop: '100px' }}>
                    <a id="descargaEnlace" href="/assets/docs/guia-uso-formulario-canje-be-focused-2024.pdf" target="_blank" rel="noopener noreferrer">
                        <img className='image-login' src="https://mcusercontent.com/9286884ec8991edd420ae584b/images/c24cc7e7-3e56-266d-23e2-f5fe4d77bc72.jpg" alt="" />
                    </a>

                    <h3 style={{ margin: '0 ', textAlign: 'center', marginTop: '15px' }} className="text-center default_text open_sans">
                        Para acceder sigue las instrucciones de <strong> <a id="descargaEnlace" href="/assets/docs/guia-uso-formulario-canje-be-focused-2024.pdf" target="_blank" rel="noopener noreferrer"> nuestra guía </a> </strong>
                    </h3><br />
                    <p style={{ margin: '0', textAlign: 'center', fontSize: '18px' }} className="text-center default_text open_sans">
                        <strong>Introduce tu correo registrado en Be Focused.</strong> <br />
                        Recibe un token en tu correo, valídalo y accede al formulario.
                    </p>
                </hgroup>

                <form className='form-login' onSubmit={handleSubmit} style={{ marginTop: '30px', marginBottom: '50px' }}>
                    <div className="group">
                        <input
                            type="email"
                            name="correo"
                            placeholder='Ingresa tu correo electronico'
                            value={correo}
                            onChange={(e) => setCorreo(e.target.value)}
                            required
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        {/* <label>Email</label> */}
                    </div>

                    {!tokenRequested ? (
                        // <button type="submit" className="button buttonBlue" id="submit-button" disabled={isLoading}>
                        <button type="submit" className="button buttonBlue" id="submit-button" disabled={isLoading}>
                            {isLoading ? 'Solicitando...' : 'Solicitar Token'}
                            <div className="ripples buttonRipples">
                                <span className="ripplesCircle"></span>
                            </div>
                        </button>
                    ) : (
                        <>
                            <div className="group" style={{ marginTop: '20px' }}>
                                <input
                                    type="text"
                                    name="token"
                                    value={token}
                                    onChange={(e) => setToken(e.target.value)}
                                    placeholder="Ingresa manualmente el token"
                                    required
                                />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                {/* <label>Token</label> */}
                            </div>
                            <button
                                type="button"
                                className="button buttonBlue"
                                id="validate-button"
                                onClick={handleTokenValidation}
                                style={{ marginTop: '20px' }}
                            >
                                Validar Token
                                <div className="ripples buttonRipples">
                                    <span className="ripplesCircle"></span>
                                </div>
                            </button>
                        </>
                    )}
                </form>

                {validationMessage && (
                    <p style={{ textAlign: 'center', color: validationMessage.includes('válido') ? 'green' : 'red' }}>
                        {validationMessage}
                    </p>
                )}
            </section>

            {/* <Modal show={showModal} onHide={handleClose} centered backdrop="static">
                <Modal.Header>
                    <Modal.Title>Información de Canje</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding:'10px'}}>
                    El proceso de Canje inicia el próximo <b>13 de noviembre</b>. Estamos cerrando la <b>totalidad de los puntos</b>. Gracias por su cooperación.
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-yellow-black btn-large btn-rounded contact_btn ' onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </div>
    );
}

export function Logout() {
    return (
        <div>
            <p>Este es el Logout</p>
        </div>
    );
}

export default Login;
