import React from 'react';

const Pagination = ({ canjeosPerPage, totalCanjeos, paginate, currentPage }) => {
    const pageNumbers = [];

    // Crear un array con los números de página
    for (let i = 1; i <= Math.ceil(totalCanjeos / canjeosPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="flex justify-center mt-4">
            <ul className="flex space-x-2">
                {pageNumbers.map(number => (
                    <li key={number} className="inline-block">
                        <a 
                            onClick={() => paginate(number)} 
                            href="#"
                            className={`px-4 py-2 border rounded-full text-gray-600 hover:bg-blue-200 ${number === currentPage ? 'bg-blue-500 text-black' : 'bg-blue'}`}
                            style={{ transition: 'background-color 0.3s, color 0.3s' }}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Pagination;
