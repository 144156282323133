import { createContext, useState } from 'react';

// Crea el contexto
export const AuthContext = createContext();

// Proveedor del contexto
export const AuthProvider = ({ children }) => {
    const [contactId, setContactId] = useState(null);

    const login = (id) => {
        setContactId(id);
        console.log(`El contacto con ID: ${id} está en sesión.`);
    };

    const logout = () => {
        setContactId(null);
        console.log('Sesión cerrada.');
    };

    return (
        <AuthContext.Provider value={{ contactId, login, logout }}>
        {children}
        </AuthContext.Provider>
    );
};
