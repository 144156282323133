import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaHome, FaFileImport  } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { IoLogOut } from "react-icons/io5";
import { FaTrash } from "react-icons/fa6";
import Swal from 'sweetalert2';
import { Modal, Button } from 'react-bootstrap';
import Pagination from '../components/Pagination';

export function LoginAdmin() {
    const [correo, setCorreo] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    
    // Función para manejar el inicio de sesión
    const handleLogin = async (correo, password) => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/login/admin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',  // Esto asegura que las cookies se envíen al backend
                body: JSON.stringify({ correo, password }),
            });

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Inicio de sesión exitoso',
                    text: 'Redirigiendo al panel de administración',
                }).then(() => {
                    navigate('/admin');
                });
            } else {
                const data = await response.json();
                Swal.fire({
                    icon: 'error',
                    title: 'Error de autenticación',
                    text: data.message || 'Correo o contraseña incorrectos',
                });
            }
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error del servidor',
                text: 'Hubo un problema al procesar tu solicitud. Intenta nuevamente más tarde.',
            });
        }
    };

    useEffect(() => {
        // Deshabilitar el estilo global del index.html
        const globalStyles = document.querySelector('link[href="/vendor/css/bundle.min.css"]');
        if (globalStyles) {
            globalStyles.disabled = true;
        }

        // Añadir estilos específicos de Admin
        const linkThemeCss = document.createElement('link');
        linkThemeCss.rel = 'stylesheet';
        linkThemeCss.href = '/assets/css/theme.min.css';
        linkThemeCss.type = 'text/css';
        document.head.appendChild(linkThemeCss);

        const linkIconsCss = document.createElement('link');
        linkIconsCss.rel = 'stylesheet';
        linkIconsCss.href = '/assets/css/icons.min.css';
        linkIconsCss.type = 'text/css';
        document.head.appendChild(linkIconsCss);

        // Limpiar estilos cuando el componente se desmonte
        return () => {
            if (globalStyles) {
                globalStyles.disabled = false;
            }
            // Remover estilos agregados dinámicamente
            document.head.removeChild(linkThemeCss);
            document.head.removeChild(linkIconsCss);
        };
    }, []);

    return (
        <div class="h-screen w-screen flex justify-center items-center" style={{background:'#243645'}}>
            <div class="2xl:w-1/4 lg:w-1/3 md:w-1/2 w-full">
                <div class="card overflow-hidden sm:rounded-md rounded-none">
                    <div class="px-6 py-8">
                        <a href="/" class="flex justify-center mb-8">
                            <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" style={{width:'60%'}} alt="logo" className="logo-scrolled" />
                        </a>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleLogin(correo, password);
                        }}>
                        <div class="mb-4">
                            <input class="form-input" type="email" placeholder="Ingresa tu correo"
                            value={correo} 
                            onChange={(e) => setCorreo(e.target.value)} 
                            required 
                            />
                        </div>

                        <div class="mb-4">
                            <div class="flex items-center justify-between mb-2">
                            </div>
                            <input  class="form-input" type="password" placeholder="Ingresa tu contraseña" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required/>
                        </div>

                        <div class="flex justify-center mb-3">
                            <button class="btn w-full text-white" type="submit" style={{background:'#243645'}}> Ingresar </button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Admin() {
    const [canjeos, setCanjeos] = useState([]);
    const [detalleCanjeo, setDetalleCanjeo] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const canjeosPerPage = 10;
    const navigate = useNavigate();

    const checkAuth = async () => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/admin', {
                method: 'GET',
                credentials: 'include',  // Asegura que las cookies se envíen en la solicitud
            });

            if (response.status === 401 || response.status === 403) {
                navigate('/login/admin');  // Redirige a la página de login si el token no es válido o no existe
            }
        } catch (error) {
            console.error('Error al verificar la autenticación:', error);
            navigate('/login/admin');
        }
    };

    useEffect(() => {
        checkAuth(); // Verifica la autenticación al cargar la página
    }, []);

    // Función para cerrar sesión
    const handleLogout = async () => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/logout', {
                method: 'GET',
                credentials: 'include',  // Asegura que la cookie se envíe en la solicitud
            });

            if (response.ok) {
                // Redirigir al login después de que se haya cerrado sesión
                navigate('/login/admin');
            } else {
                console.error('Error al cerrar sesión:', await response.json());
            }
        } catch (error) {
            console.error('Error al cerrar sesión:', error);
        }
    };

    // Función para cerrar el modal
    const handleClose = () => {
        setShowModal(false); // Cambia el estado para cerrar el modal
    };

    // Declarar fetchCanjeos fuera del useEffect para que esté disponible en todo el componente
    const fetchCanjeos = async () => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/admin/canjeos');
            const data = await response.json();
            setCanjeos(data);
        } catch (error) {
            console.error('Error al obtener los canjeos:', error);
        }
    };

    // useEffect para llamar a fetchCanjeos cuando el componente se monte
    useEffect(() => {
        fetchCanjeos(); // Llamar a fetchCanjeos cuando el componente se cargue
    }, []);

    // Función para abrir el modal y obtener los detalles del canjeo
    const fetchDetalleCanjeo = async (contId, fechaCanjeo) => {
        try {
            const response = await fetch(`https://system-befocused-production.up.railway.app/admin/canjeo-detalle/${contId}/${fechaCanjeo}`);
            const data = await response.json();

            if (response.ok) {
                // Mostrar el modal con los detalles del canjeo
                setDetalleCanjeo(data); // Guardar los datos en el estado
                setShowModal(true); // Abrir el modal
            } else {
                console.error('Error al obtener los detalles del canjeo:', data.message);
            }
        } catch (error) {
            console.error('Error al obtener el detalle del canjeo:', error);
        }
    };

    const eliminarCanjeo = (fechaAcumulacion) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción es irreversible. El canje será eliminado permanentemente.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(`https://system-befocused-production.up.railway.app/admin/eliminar-canjeo/${fechaAcumulacion}`, {
                        method: 'DELETE'
                    });
                    const data = await response.json();
                    if (response.ok) {
                        Swal.fire('Eliminado', 'El canje ha sido eliminado correctamente.', 'success');
                        // Llamar a fetchCanjeos para actualizar la lista después de eliminar
                        fetchCanjeos();  // Asegúrate de que fetchCanjeos está correctamente definido
                    } else {
                        Swal.fire('Error', data.message || 'No se pudo eliminar el canje', 'error');
                    }
                } catch (error) {
                    console.error('Error al eliminar el canje:', error);
                    Swal.fire('Error', 'Hubo un problema al intentar eliminar el canje.', 'error');
                }
            }
        });
    };

    // Calcular el índice de inicio y fin de los canjeos para la página actual
    const indexOfLastCanjeo = currentPage * canjeosPerPage;
    const indexOfFirstCanjeo = indexOfLastCanjeo - canjeosPerPage;
    const currentCanjeos = canjeos.slice(indexOfFirstCanjeo, indexOfLastCanjeo);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        // Deshabilitar el estilo global del index.html
        const globalStyles = document.querySelector('link[href="/vendor/css/bundle.min.css"]');
        if (globalStyles) {
            globalStyles.disabled = true;
        }

        // Añadir estilos específicos de Admin
        const linkThemeCss = document.createElement('link');
        linkThemeCss.rel = 'stylesheet';
        linkThemeCss.href = '/assets/css/theme.min.css';
        linkThemeCss.type = 'text/css';
        document.head.appendChild(linkThemeCss);

        const linkIconsCss = document.createElement('link');
        linkIconsCss.rel = 'stylesheet';
        linkIconsCss.href = '/assets/css/icons.min.css';
        linkIconsCss.type = 'text/css';
        document.head.appendChild(linkIconsCss);

        // Limpiar estilos cuando el componente se desmonte
        return () => {
            if (globalStyles) {
                globalStyles.disabled = false;
            }
            // Remover estilos agregados dinámicamente
            document.head.removeChild(linkThemeCss);
            document.head.removeChild(linkIconsCss);
        };
    }, []);

    return (
        <div className='app-wrapper'>
            <div class="app-menu">
                <a href="/" class="logo-box">
                    <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" style={{width:'70%'}} alt="logo" className="logo-scrolled" />
                </a>
                <div data-simplebar>
                    <ul class="menu" data-fc-type="accordion">
                        <li class="menu-title">Men&uacute;</li>

                        <li class="menu-item">
                            <a href="/admin" class="menu-link waves-effect">
                                <span class="menu-icon"><FaHome /></span>
                                <span class="menu-text"> Panel </span>
                            </a>
                        </li>

                        <li class="menu-item">
                            <a href="/admin/importar" class="menu-link waves-effect">
                                <span class="menu-icon"><FaFileImport /></span>
                                <span class="menu-text"> Importar </span>
                            </a>
                        </li>

                        <li class="menu-item">
                            <a href="#" onClick={handleLogout} class="menu-link waves-effect">
                                <span class="menu-icon"><IoLogOut /></span>
                                <span class="menu-text"> Cerrar Sesión </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='app-content'>
                <main className="p-6">
                    <div class="flex justify-between items-center mb-6">
                        <h4 class="text-slate-900 text-lg font-medium">Panel</h4>
                    </div>
                    <div class="grid xl:grid-cols-1 md:grid-cols-1 gap-6 mb-12">
                        <div class="col-xl-12 col-md-12">
                            <div class="card">
                                <div class="p-5">
                                    <div class="card bg-white overflow-hidden">
                                        <div class="card-header">
                                            <h4 class="card-title">Listas de Canje</h4>
                                        </div>
                                        <div>
                                            <div class="overflow-x-auto">
                                                <div class="min-w-full inline-block align-middle">
                                                    <div class="overflow-hidden">
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500"># Canje</th>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Distribuidor</th>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Canal</th>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Nombre</th>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Puntos Canjeados</th>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Fecha</th>
                                                                <th scope="col" className="px-6 py-3 text-start text-sm text-gray-500">Acción</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200">
                                                            {currentCanjeos.map((canjeo, index) => (
                                                                <tr className="hover:bg-gray-100" key={index}>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800" style={{ textAlign: 'center' }}>{index + 1}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{canjeo.nombre_distribuidor}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{canjeo.nombre_canal}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{canjeo.nombre_usuario}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{Math.abs(canjeo.total_puntos_canjeados)}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{new Date(canjeo.fecha_canjeo).toLocaleString('es-MX')}</td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                                            <a 
                                                                                className="text-primary hover:text-sky-700" 
                                                                                href="#"
                                                                                onClick={() => {
                                                                                    const fecha = new Date(canjeo.fecha_canjeo);
                                                                                    const fechaFormateada = fecha.getFullYear() + '-' +
                                                                                        ('0' + (fecha.getMonth() + 1)).slice(-2) + '-' +
                                                                                        ('0' + fecha.getDate()).slice(-2) + ' ' +
                                                                                        ('0' + fecha.getHours()).slice(-2) + ':' +
                                                                                        ('0' + fecha.getMinutes()).slice(-2) + ':' +
                                                                                        ('0' + fecha.getSeconds()).slice(-2);

                                                                                    fetchDetalleCanjeo(canjeo.CONT_ID, fechaFormateada);
                                                                                }}
                                                                                style={{ fontSize: '1.5rem' }}
                                                                            >
                                                                                <FaEye />
                                                                            </a>
                                                                            <a 
                                                                                className="text-primary hover:text-sky-700" 
                                                                                href="#" 
                                                                                onClick={() => {
                                                                                    const fecha = new Date(canjeo.fecha_canjeo);
                                                                                    const fechaFormateada = fecha.getFullYear() + '-' +
                                                                                        ('0' + (fecha.getMonth() + 1)).slice(-2) + '-' +
                                                                                        ('0' + fecha.getDate()).slice(-2) + ' ' +
                                                                                        ('0' + fecha.getHours()).slice(-2) + ':' +
                                                                                        ('0' + fecha.getMinutes()).slice(-2) + ':' +
                                                                                        ('0' + fecha.getSeconds()).slice(-2);
                                                                                    
                                                                                    eliminarCanjeo(fechaFormateada);
                                                                                }} 
                                                                                style={{ fontSize: '1.2rem' }}
                                                                            >
                                                                                <FaTrash />
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    {/* Componente de paginación */}
                                                    <Pagination 
                                                        canjeosPerPage={canjeosPerPage} 
                                                        totalCanjeos={canjeos.length} 
                                                        paginate={paginate} 
                                                        currentPage={currentPage}
                                                    />
                                                    <Modal show={showModal} onHide={handleClose}>
                                                        <Modal.Header style={{display:'flex', justifyContent:'space-between'}}>
                                                            <Modal.Title style={{ color: '#000', textAlign:'center', fontSize:'25px', marginBottom:'10px' }}>Detalle del Canjeo</Modal.Title>
                                                            <IoMdClose onClick={handleClose} style={{cursor:'pointer'}}/>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            {detalleCanjeo ? (
                                                                <div class="p-4 overflow-y-auto" style={{paddingTop:'0px', paddingLeft:'0px'}}>
                                                                    <h5 style={{marginBottom:'5px'}}>Información del Solicitante</h5>
                                                                    <p><strong>Nombre del Solicitante:</strong> {detalleCanjeo.direccion.DOM_nombre_solicitante}</p>
                                                                    <p><strong>Correo Electrónico:</strong> {detalleCanjeo.direccion.DOM_correo}</p>
                                                                    <p><strong>Teléfono:</strong> {detalleCanjeo.direccion.DOM_telefono}</p>
                                                                    <p><strong>Dirección:</strong> {detalleCanjeo.direccion.direccion}</p>
                                                                    <p><strong>Entre Calles:</strong> {detalleCanjeo.direccion.DOM_entre_calles}</p>
                                                                    <p><strong>Notas:</strong> {detalleCanjeo.direccion.DOM_notas || 'N/A'}</p>
                                                                    <hr style={{marginBottom:'10px'}}></hr>
                                                                    <h5>Productos Canjeados</h5>
                                                                    <table className="min-w-full divide-y divide-gray-200">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope='col' className='px-6 py-3 text-start text-sm text-gray-500'>Producto</th>
                                                                                <th scope='col' className='px-6 py-3 text-sm text-gray-500'>Cantidad</th>
                                                                                <th scope='col' className='px-6 py-3 text-sm text-gray-500'>Puntos Canjeados</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className='divide-y divide-gray-200'>
                                                                            {detalleCanjeo.productos.map((producto, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{producto.PROD_nombre}</td>
                                                                                    <td style={{textAlign:'center'}}>{producto.PP_cantidad}</td>
                                                                                    <td style={{textAlign:'center'}}>{producto.PP_canjeados}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <br></br>

                                                                    <h5>Total Puntos Canjeados: {detalleCanjeo.totalPuntos}</h5>
                                                                </div>
                                                            ) : (
                                                                <p>Cargando detalles del canje...</p>
                                                            )}
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <div className="text-right w-100">
                                                                <Button 
                                                                    className="btn rounded-full border border-info text-info hover:bg-info hover:text-white ml-auto" 
                                                                    onClick={handleClose}
                                                                >
                                                                    Cerrar
                                                                </Button>
                                                            </div>
                                                        </Modal.Footer>
                                                    </Modal>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export function ExportAdmin() {
    
    const navigate = useNavigate();

    const checkAuth = async () => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/admin', {
                method: 'GET',
                credentials: 'include',  // Asegura que las cookies se envíen en la solicitud
            });

            if (response.status === 401 || response.status === 403) {
                navigate('/login/admin');  // Redirige a la página de login si el token no es válido o no existe
            }
        } catch (error) {
            console.error('Error al verificar la autenticación:', error);
            navigate('/login/admin');
        }
    };

    useEffect(() => {
        checkAuth(); // Verifica la autenticación al cargar la página
    }, []);

    // Función para cerrar sesión
    const handleLogout = async () => {
        try {
            const response = await fetch('https://system-befocused-production.up.railway.app/logout', {
                method: 'GET',
                credentials: 'include',  // Asegura que la cookie se envíe en la solicitud
            });

            if (response.ok) {
                // Redirigir al login después de que se haya cerrado sesión
                navigate('/login/admin');
            } else {
                console.error('Error al cerrar sesión:', await response.json());
            }
        } catch (error) {
            console.error('Error al cerrar sesión:', error);
        }
    };

    useEffect(() => {
        // Deshabilitar el estilo global del index.html
        const globalStyles = document.querySelector('link[href="/vendor/css/bundle.min.css"]');
        if (globalStyles) {
            globalStyles.disabled = true;
        }

        // Añadir estilos específicos de Admin
        const linkThemeCss = document.createElement('link');
        linkThemeCss.rel = 'stylesheet';
        linkThemeCss.href = '/assets/css/theme.min.css';
        linkThemeCss.type = 'text/css';
        document.head.appendChild(linkThemeCss);

        const linkIconsCss = document.createElement('link');
        linkIconsCss.rel = 'stylesheet';
        linkIconsCss.href = '/assets/css/icons.min.css';
        linkIconsCss.type = 'text/css';
        document.head.appendChild(linkIconsCss);

        // Limpiar estilos cuando el componente se desmonte
        return () => {
            if (globalStyles) {
                globalStyles.disabled = false;
            }
            // Remover estilos agregados dinámicamente
            document.head.removeChild(linkThemeCss);
            document.head.removeChild(linkIconsCss);
        };
    }, []);

    return(
        <div className='app-wrapper'>
            <div class="app-menu">
                <a href="/" class="logo-box">
                    <img src="/agency-studio/img/logo-be-focused-panduit-latam.png" style={{width:'70%'}} alt="logo" className="logo-scrolled" />
                </a>
                <div data-simplebar>
                    <ul class="menu" data-fc-type="accordion">
                        <li class="menu-title">Menú</li>

                        <li class="menu-item">
                            <a href="/admin" class="menu-link waves-effect">
                                <span class="menu-icon"><FaHome /></span>
                                <span class="menu-text"> Panel </span>
                            </a>
                        </li>

                        <li class="menu-item">
                            <a href="/admin/importar" class="menu-link waves-effect">
                                <span class="menu-icon"><FaFileImport /></span>
                                <span class="menu-text"> Importar </span>
                            </a>
                        </li>

                        <li class="menu-item">
                            <a href="#" onClick={handleLogout} class="menu-link waves-effect">
                                <span class="menu-icon"><IoLogOut /></span>
                                <span class="menu-text"> Cerrar Sesión </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='app-content'>
                <main className="p-6"></main>
            </div>
        </div>
    )
}



export default Admin